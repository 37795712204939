<template>
    <div>
        <span class="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
        {{textDF}}
    </div>
</template>
<script type="text/javascript">
export default {
        props: {
            text: String
        },
        computed:{
            textDF(){
                if(typeof this.text == 'undefined'){
                    return 'Cargando...'
                }
                return this.text
            }
        }
    }
</script>