<template>
	<button ref="signinBtn" class="g-signin-button">
		<BtnLoading v-if="loadingLoginGoogle" />
		<span v-else>
			<img :src="imgGoogle" alt="Google" class="w-20px h-20px mx-1">
			Google
		</span>
	</button>
</template>
<script type="text/javascript">
	import BtnLoading from '@/components/BtnLoading.vue'
	import imgGoogle from '@/assets/img/login/google.svg'
	//import { inject, toRefs } from "vue"

	export default {
		props: ['disabled'],
		components:{
			BtnLoading
		},
		data () {
			return {
				imgGoogle,
				loadingLoginGoogle: false
			}
		},
		mounted(){
			this.installClient().then(() => {
				return this.initClient({
					client_id: '1077350013200-jt18gm4th713c9nu7on776abk7jrtssp.apps.googleusercontent.com'
				})
			})
			.then((gapi) => {
				const auth2 = gapi.auth2.getAuthInstance()
				auth2.attachClickHandler(this.$refs.signinBtn, {}, googleUser => {
					this.onSignInSuccess(googleUser)
				}, error => {
					this.onSignInError(error)
				})

			}).catch((error) => {
				console.error(error);
			})
		},
		methods: {
			installClient(){
				const apiUrl = 'https://apis.google.com/js/api:client.js'
				return new Promise((resolve) => {
					let script = document.createElement('script')
					script.src = apiUrl
					script.onreadystatechange = script.onload = function () {
						if (!script.readyState || /loaded|complete/.test(script.readyState)) {
							setTimeout(function () {
								resolve()
							}, 500)
						}
					}
					document.getElementsByTagName('head')[0].appendChild(script);
				})
			},

			initClient(config) {
				return new Promise((resolve, reject) => {
					window.gapi.load('auth2', () => {
						window.gapi.auth2.init(config).then(() => {
							resolve(window.gapi)
						}).catch((error) => {
							reject(error)
						})
					})
				})
			},
			onSignInSuccess (googleUser) {
				var data = googleUser.getAuthResponse(true)
				var access_token = data.access_token || ''
				
				this.loadingLoginGoogle = true
				this.$store.dispatch('socialLogin', {access_token: access_token, provider: 'google'}).then(res=>{
					var data = res.data || {}
					this.$toast.info(`Bienvenid@ ${data.NOMBRES} ${data.APELLIDOS}`)
					this.$router.push({name: 'home'})
					return res
				}).catch(err => {
					if(err.response){
						if(typeof err.response.data === 'string'){
							this.$toast.error(err.response.data)
						}else{
							this.$toast.error('Credenciales incorrectas')
						}
					}
				}).then(()=> {
					this.loadingLoginGoogle = false
				})
			},
			onSignInError (error) {
				console.log('OH NOES', error)
			}
		},
		computed: {
			disabledVal(){
				return this.disabled
			}
		},
	}
</script>