<template>
  <button type="button" @click="logInWithFacebook">
    <BtnLoading v-if="loadingLoginFacebook" />
    <span v-else>
        <font-awesome-icon :icon="faFacebook" style="font-size: 22px; color: #1877f2;" />
        Facebook
    </span>
  </button>
</template>
<script>
import BtnLoading from '@/components/BtnLoading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
export default {
	name:"facebookLogin",
	components: {
		FontAwesomeIcon,
		BtnLoading
	},
	data(){
		return{
			loadingLoginFacebook: false,
			faFacebook,
		}
	},
	mounted(){
		if (this.query.access_token) {
			localStorage.setItem("loginFacebook", JSON.stringify({
				clientId: this.appId,
				code: this.query.access_token,
				redirectUri: this.UrlPage,
				url: '/auth/facebook'
			}))
			this.loadingLoginFacebook = true
			this.$store.dispatch('socialLogin', {access_token: this.query.access_token, provider: 'google'}).then(res=>{
				var data = res.data || {}
				this.$toast.info(`Bienvenid@ ${data.NOMBRES} ${data.APELLIDOS}`)
				//this.$router.push({name: 'home'})
				window.close()
				return res
			}).catch(err => {
				if(err.response){
					if(typeof err.response.data === 'string'){
						this.$toast.error(err.response.data)
					}else{
						this.$toast.error('Credenciales incorrectas')
					}
				}
			}).then(()=> {
				this.loadingLoginFacebook = false
			})
			//this.$emit('facebook', )
		}
	},
	methods: {
		logInWithFacebook() {
			var mod = this
			const qs = Object.keys(mod.params).map(key => `${key}=${mod.params[key]}`).join('&')
			var url = `https://www.facebook.com/v2.3/dialog/oauth?${qs}`
			var facebook = window.open(url, 'facebook', "width=580,height=400,left=393,top=131.2")
			facebook.onbeforeunload = function(){
				mod.setToken()
			}
		},
		setToken(){
			const items = JSON.parse(localStorage.getItem("loginFacebook"))
			localStorage.removeItem("loginFacebook")
			this.$emit('facebook', items)
		}
	},
	computed: {
		query(){
			return this.$route.query || {}
		},
		params(){
			return {
				"response_type": "token",
				"client_id": this.appId,
				"redirect_uri": this.UrlPage,
				"display": "popup",
				"scope": "email"
			}
		},
		appId(){
			return process.env.NODE_ENV === 'production' ? '558460118923269' : '724501014940602'
		},
		UrlPage(){
			return window.location.origin+"/inicio-sesion"
		}
	}
}
</script>