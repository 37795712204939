<template>
    <div class="vh-lg-100 position-relative">
        <div class="row justify-content-center align-items-center h-100 mx-0">
            <div class="col-lg-4 p-0 h-100">
                <div class="bg-primary text-center h-100 d-flex flex-column">
                    <img :src="ImgBG" alt="Iniciar sesión" class="w-100 h-100 img-background">
                </div>
            </div>
            <div class="col-lg-8 p-0 bg-white h-100">
                <div class="row justify-content-center h-100 mx-0">
                    <div class="col-xl-6 col-xxl-4 h-100 p-3">
                        <div class="d-flex flex-column h-100">
                            <div class="flex-fill d-flex align-items-center w-100">
                                <div class="w-100">
                                    <h1 class="mb-1">Iniciar sesión</h1>
                                    <div class="text-muted mb-3 mb-lg-5">
                                        ¿Nuevo aquí?
                                        <router-link :to="{name: 'register'}" class="text-primary" :disabled="loading">Crea una cuenta</router-link>
                                    </div>
                                    <form type="POST" v-on:submit.prevent="ingresar" ref="userForm">
                                        <div class="mb-2">
                                            <label for="email-login" class="form-label" v-if="false">
                                                Correo electrónico
                                            </label>
                                            <input type="email" id="email-login" class="form-control" placeholder="Correo electrónico" v-model="form.email" required>
                                            <span v-if="errors.email" class="text-danger">
                                                {{errors.email[0]}}
                                            </span>
                                        </div>
                                        <div class="mb-2">
                                            <div class="d-flex justify-content-end">
                                                <label for="password-login" class="form-label" v-if="false">
                                                    Contraseña
                                                </label>
                                                <a href="#" tabindex="-1">
                                                    ¿Olvidaste tu contraseña?
                                                </a>
                                            </div>
                                            <div class="position-relative">
                                                <input :type="passwordDF" id="password-login" class="form-control" placeholder="Contraseña" style="padding-right: 42px;" v-model="form.password" autocomplete="on">
                                                <div class="position-absolute" style="right: 5px; top: 3px;">
                                                    <button type="button" class="btn btn-transparent btn-sm border-0 text-muted shadow-0" v-on:click="password=!password" tabindex="-1">
                                                        <font-awesome-icon :icon="faEyeSlash" v-if="password" />
                                                        <font-awesome-icon :icon="faEye" v-else />
                                                    </button>
                                                </div>
                                            </div>
                                            <span v-if="errors.password" class="text-danger">
                                                {{errors.password[0]}}
                                            </span>
                                        </div>
                                        <div class="mb-3 d-flex">
                                            <div class="mt-auto">
                                                <button type="submit" class="btn btn-primary me-2 px-3" :disabled="loading || !validateForm">
                                                    <BtnLoading v-if="loadingLogin" />
                                                    <span v-else>
                                                        <font-awesome-icon :icon="faSignInAlt" /> Acceder
                                                    </span>
                                                </button>
                                            </div>
                                            <div class="text-center">
                                                <small class="text-muted">Ó inicia sesión con</small>
                                                <br>
                                                <BtnGoogleLogin type="button" class="btn btn-outline-primary me-2" :disabled="loading" />
                                                <BtnFacebookLogin type="button" class="btn btn-outline-primary me-2" :disabled="loading" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="mb-3">
                                <router-link :to="{name: 'login'}" class="btn btn-link" :disabled="loading">Términos</router-link>
                                <router-link :to="{name: 'home'}" class="btn btn-link mx-2" :disabled="loading">Inicio</router-link>
                                <router-link :to="{name: 'contacto'}" class="btn btn-link" :disabled="loading">Contáctanos</router-link>
                            </div>
                        </div>
                        <div class="text-muted text-center border-top pt-3" v-if="false">
                            <h3>¿No tienes una cuenta?</h3>
                            <router-link :to="{name: 'register'}" class="btn btn-secondary btn-lg" :disabled="loading">Registrate gratis</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope, faLock, faEyeSlash, faEye, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import ImgBG from '@/assets/img/backgrounds/login.jpg'
//import ImgBGRosas from '@/assets/img/tres-rosas.png'
//import ImgBGCamaraRosas from '@/assets/img/camara-rosas.png'
import LogoDark from '@/assets/img/logo_dark.png'
import imgGoogle from '@/assets/img/login/google.svg'
import BtnLoading from '@/components/BtnLoading.vue'
import BtnGoogleLogin from '@/components/auth/BtnGoogleLogin.vue'
import BtnFacebookLogin from '@/components/auth/BtnFacebookLogin.vue'

export default {
    name: 'inicio-sesion',
    components: {
        FontAwesomeIcon,
        BtnLoading,
        BtnGoogleLogin,
        BtnFacebookLogin
    },
    data(){
        return {
            ImgBG,
            LogoDark,
            //ImgBGRosas,
            //ImgBGCamaraRosas,
            imgGoogle,
            faEnvelope,
            faLock,
            faEyeSlash,
            faEye,
            faSignInAlt,
            validateForm: false,
            password: true,
            form: {
                email: '',
                password: ''
            },
            loadingLogin: false
        }
    },
    mounted(){
        this.$store.commit('showMenu', false)
    },
    unmounted(){
        this.$store.commit('showMenu', true)
    },
    methods: {
        ingresar(){
            this.checkValidity()
            if (!this.validateForm) {
                return
            }
            this.loadingLogin = true
            this.$store.dispatch('login', this.form).then((res) => {
                var data = res.data || {}
                this.$toast.info(`Bienvenid@ ${data.NOMBRES} ${data.APELLIDOS}`)
                this.$router.push({name: 'home'})
                return res
            }).catch(err => {
                if(err.response){
                    if(typeof err.response.data === 'string'){
                        this.$toast.error(err.response.data)
                    }else{
                        this.$toast.error('Credenciales incorrectas')
                    }
                }
            }).then(() => {
                this.loadingLogin = false
            })
        },
        checkValidity(){
            if (!this.$refs.userForm){
                this.validateForm = false
            }else{
                this.validateForm = this.$refs.userForm.checkValidity()
            }
        },
    },
    computed: {
        passwordDF(){
            return this.password ? 'password' : 'text'
        },
        loading() {
            return this.$store.getters.loading
        },
        errors() {
            return this.$store.getters.errors || {}
        }
    },
    watch:{
        'form':{
            handler(val){
                this.checkValidity()
                return val
            },
            deep: true
        }
    }
}
</script>